<style lang="less" scoped>
@import './index.less';
</style>
<template>
  <suy-table v-model:columns="columns" :reload="loadData">
    <template #search>
      <a-form ref="formQueryRef" layout="inline" :model="searchForm">
        <a-form-item label="">
          <a-input v-model:value="searchForm.name" placeholder="员工名称"/>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="searchData">
            <template #icon>
              <SearchOutlined />
            </template>查询
          </a-button>
        </a-form-item>
        <a-form-item>
          <a-button @click="zoneClick">所有已配置的区域</a-button>
        </a-form-item>
      </a-form>
    </template>

    <a-table :columns="[sortColumn, ...columns]" :row-key="record => record.id" :data-source="listData"
      :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #sort="{ index }">
        {{ index + 1 }}
      </template>
      <template #avatarFileUrl="{ record }">
        <a-avatar :src="`${record.avatarFileUrl}`" :srcset="`${record.avatarFileUrl}`" size="large"
          style="color: #007bff;font-size: large;">{{ record.name.slice(0, 1) }}</a-avatar>
      </template>
      <template #operation="{ record }">
        <span>
          <a @click="region(record.id)">区域</a>
        </span>
      </template>
    </a-table>
  </suy-table>
  <a-modal v-model:visible="zoneShow" title="员工授权办事区域列表" width="40%" footer="" :bodyStyle="{ paddingRight: '0' }">
    <div class="zone-div">
      <div class="search-div">
        <a-form-item label="员工名称">
          <a-input v-model:value="searchZoneForm.empName" />
        </a-form-item>
        <a-form-item>
          <a-button style="margin:0 15px;" type="primary" @click="searchZoneData">
            <template #icon>
              <SearchOutlined />
            </template>查询
          </a-button>
          <a-button @click="resetZoneData">重置</a-button>
        </a-form-item>
      </div>
      <div v-show="zoneLoading" style="width: 100%;display:flex;justify-content: center;"> <a-spin size="large" /></div>
      <div v-for="item in zoneList" :key="item.empId">
        <div><span>员工名称：{{ item.empName }}</span></div>
        <div>
          <span>负责区域：</span>
          <span v-show="!item.cityName">暂无负责区域</span>
          <a-tag v-show="item.cityName" color="green" v-for="v in item.cityName?.split(',')">{{ v }}</a-tag>
        </div>
        <a-divider />
      </div>
    </div>
  </a-modal>
  <cityResourceTree ref="cityResourceTree" />
</template>
<script>
import { page, getOfficeAll } from '@/api/system/employee'
import { toRefs, reactive, onMounted, ref } from 'vue'
import { SearchOutlined } from '@ant-design/icons-vue'
// import { message } from 'ant-design-vue'
import SuyTable from '@/components/SuyTable'
import cityResourceTree from './cityResourceTree'
import { message } from 'ant-design-vue'
export default {
  name: 'employee',
  components: {
    SuyTable,
    SearchOutlined,
    cityResourceTree
  },
  setup () {
    const formRef = ref()
    const state = reactive({
      cityResourceTree: ref(null),
      formRef: ref(),
      loading: false,
      zoneLoading: false,
      zoneShow: false,
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      searchZoneForm: {
        empName: ''
      },
      searchForm: {},
      listData: [],
      zoneList: [],
      mirrorZoneList: [],
      sortColumn: { title: '序号', slots: { customRender: 'sort' } },
      columns: [
        {
          title: '姓名',
          dataIndex: 'name',
          key: 'name',
          fixed: 'left'
        },
        {
          title: '账号',
          dataIndex: 'username',
          key: 'username',
          fixed: 'left'
        },
        {
          title: '头像',
          dataIndex: 'avatarFileUrl',
          slots: {
            customRender: 'avatarFileUrl'
          }
        },
        {
          title: '性别',
          dataIndex: 'genderType.label'
        },
        {
          title: '联系方式',
          dataIndex: 'mobile'
        },
        {
          title: '工号',
          dataIndex: 'no'
        },
        {
          title: '部门',
          dataIndex: 'orgName'
        },
        {
          title: '职务',
          dataIndex: 'positionName'
        },
        {
          title: '入职时间',
          dataIndex: 'entryDate'
        },

        {
          title: '操作',
          dataIndex: 'operation',
          width: 300,
          slots: {
            customRender: 'operation'
          },
          fixed: 'right'
        }
      ]

    })

    const loadData = async () => {
      state.loading = true
      await page({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          if (res.data) {
            state.listData = res.data.records
            state.pagination.total = res.data.total
            state.loading = false
          }
        } else message.error(res.msg)
      })
      // const { data, data: { records } } = await page({
      //   ...state.searchForm,
      //   current: state.pagination.current,
      //   size: state.pagination.pageSize
      // })
      // state.listData = records.map(item => {
      //   return { ...item, isEnable: !!item.isEnable.value }
      // })
    }
    onMounted(loadData)

    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }

    const searchData = () => {
      state.pagination.current = 1
      loadData()
    }

    const region = (record) => {
      state.cityResourceTree.onClose(true)
      state.cityResourceTree.loadData(record)
    }
    const zoneClick = () => {
      state.zoneShow = true
      state.zoneLoading = true
      getOfficeAll().then(res => {
        if (res.code === 10000) {
          state.zoneList = res.data
          state.mirrorZoneList = JSON.stringify(res.data)
          state.zoneLoading = false
        }
      })
    }
    const resetZoneData = () => {
      state.searchZoneForm.empName = ''
      state.zoneList = []
      state.zoneLoading = true
      getOfficeAll().then(res => {
        if (res.code === 10000) {
          state.zoneList = res.data
          state.mirrorZoneList = JSON.stringify(res.data)
          state.zoneLoading = false
        }
      })
    }
    const searchZoneData = () => {
      // 查询筛选 ↓
      if (state.searchZoneForm.empName === '') state.zoneList = JSON.parse(state.mirrorZoneList)
      else {
        for (const key in state.searchZoneForm) {
          if (state.searchZoneForm[key] == '') continue
          state.zoneList = JSON.parse(state.mirrorZoneList).filter(item => {
            return JSON.stringify(state.searchZoneForm[key]) == JSON.stringify(item[key])
          })
        }
        setTimeout(() => {
          console.log(state.zoneList);
        }, 500)
      }
    }

    return {
      ...toRefs(state),
      loadData,
      region,
      searchData,
      handleTableChange,
      zoneClick,
      resetZoneData,
      searchZoneData,
      formRef
    }
  }
}
</script>
